/* CountrySelector.css */

.country-selector {
    position: relative;
    width: "100%";
    /* font-family: Arial, sans-serif; */
  }
  
  .selected-option {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #FBCE37;
    cursor: pointer;
    color:#FBCE37;

    /* background-color: #fff; */
    user-select: none;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  
  .selected-option.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .selected-option div {
    display: flex;
    align-items: center;
  }
  
  .options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    border-top: none;
    background-color: #fff;
    color: black;
    /* background-color: #FBCE371A; */
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  
  .search-box {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #FBCE37;
    border-top: none;
    color: black;
  }

  
  .options ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  
  .option div {
    display: flex;
    align-items: center;
  }
  
  .option:hover {
    background-color: #f0f0f0;
  }
  
  .iconify {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  
  .hide {
    display: none;
  }
  
  .phone-input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #FBCE37;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: transparent !important;
    outline: none;
    font-size: 23px;

  }

  .phone-input{
    color:#FBCE37
  }