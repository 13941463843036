* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body, iframe {
  scroll-behavior: smooth;

  padding: 0;
  margin: 0;

  
  overflow-x: hidden;
  height: 100%;

  font-family: 'AllertaRegular';
  font-style:normal
  

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000000s ease-in-out 0s;
}

input:-webkit-autofill{
  -webkit-text-fill-color: #FBCE37 !important;
}

@font-face {
  font-family: "AllertaRegular";
  src: local("AllertaRegular"),
    url("./fonts/Allerta-Regular.ttf") format("truetype");
  font-weight: normal;
}

h1 {
  color: white;
}


p, label, h4{
  color: #FBCE37;
  font-weight: 500;
}



h3{
  color:gray;
  font-weight: bold;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom:30px ;
}



li{
  color:#FBCE37 ;
  border-color: #FBCE37;
  background: #FBCE371A;
  border-radius: 30px;
  font-weight: 500;
}

input:hover{
  background: rgba(251, 206, 55, 0.3);
  cursor: pointer;
}

input[type="text"], input[type="email"]{
  color:#FBCE37;
  background: transparent;
  font-size: 23px;
  /* font-weight: 00; */
  height: 3rem;
  border-bottom: 1px solid #FBCE37;
  transition: ease-in-out 0.1s;
  

}

input[type="text"]:focus, input[type="email"]:focus{
   border-bottom: 2px solid #FBCE37;
   

}

input::placeholder{
  font-weight: 500;
  color:#FBCE37 ;
  opacity: 0.3;
}

button{
  background-color: white;
  border-radius: 20px;
}

button p{
  color: rgb(0, 88, 229);
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
}




/* HTML: <div class="triangle"></div> */
.triangle {
  --r:2px; /* border radius */

  width: 60px;
  aspect-ratio: 1;
  border-top-right-radius: var(--r);
  clip-path: polygon(100% 100%,0 0,100% 0);
  --_g:calc(50%*(1 + 1/tan(22.5deg)) - var(--r)/(3*sqrt(2) - 4));
  --_r:calc(var(--r)/tan(22.5deg)),#000 98%,#0000 101%;
  -webkit-mask:
    radial-gradient(var(--r) at top   var(--r) left   var(--_r)),
    radial-gradient(var(--r) at right var(--r) bottom var(--_r)),
    conic-gradient(from 22.5deg at calc(100% - var(--_g)) var(--_g),#000 45deg,#0000 0);
  background: linear-gradient(45deg,#FBCE37,#FBCE37);
}

